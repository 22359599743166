import React from 'react';
import { getImageByKey } from '../../scripts/getImageByKey';
import * as classes from './AboutUs.module.css';
function AboutUs() {
  return (
    <div className={classes.aboutUsImage}>
      <img
        src={getImageByKey('aboutus')}
        alt='aboutus'
        height={'100%'}
        width={'100%'}
      />
    </div>
  );
}

export default AboutUs;

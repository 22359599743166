import './App.css';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useMyContext } from './contexts/StateHolder';

import settings from './config_settings.json';
import BottomBar from './components/Footers/BottomBar';

import styles from './config_demo.json';

import { getCategories } from './scripts/dataHandlers';

import './shared/FontAwesomeIcon';

import ChosenCategoryGrid from './components/ViewAssets/Grids/ChosenCategoryGrid';
import CategoryContentSimiliar from './components/ViewAssets/Sliders/CategoryContentSimiliar';
import SeriesAsset from './components/ViewAssets/Sliders/SeriesAsset/SeriesAsset';

import ViewedSection from './components/ViewedSection/ViewedSection';
import EditorChoice from './components/EditorChoice/EditorChoice';
import ChannelsMainPage from './components/Channels/Channels/ChannelsMainPage';
import DetailsSerie from './components/Details/DetailsSerie';

import ContactUs from './components/ContactUs/ContactUs';
import Pricing from './components/Pricing/Pricing';

import Channel from './components/Channels/Channel/Channel';
import { useCookies } from 'react-cookie';
// import DetailsVideoNew from './components/Details/DetailsVideoNew';
import EventsCategoryItem from './components/Events/EventsCategory/EventsCategoryItem';
import UpcomingEvent from './components/Events/Event/UpcomingEvent';
import UpcomingEvents from './components/Events/EventsCategory/UpcomingEvents';
import EventsEditorChoice from './components/Events/EventsEditorChoice/EventsEditorChoice';
import EventsCategory from './components/Events/EventsCategory/EventsCategory';
import NavBarButton from './components/NavBars/NavBarButton';
import NavBarCategoryDropDown from './components/NavBars/NavBarCategoryDropdown';
import NavBar from './components/NavBars/NavBar';

import LoginBTN from './components/Login/LoginBTN';
import * as classes from './components/NavBars/NavBar.module.css';
import Banner from './components/ViewAssets/Sliders/Banner';
import RenderCategoriesWithTitles from './components/ViewAssets/Sliders/CategoriesWithTitles';
import Promo from './components/ViewAssets/Sliders/Promo';
import LiveNowEvents from './components/Events/EventsCategory/LiveNowEvents';

import RenderCategoryDropdown from './components/DropDowns/CategoryDropdown';
import PurchaseFlow from './components/Packages/PurchaseFlow';
import { useTranslation } from 'react-i18next';
import TOS from './components/TOS/TOS';
import PrivacyPolicy from './components/PrivacyPolicy/PrivacyPolicy';
import Search from './components/Search/Search';
import DetailsVideo from './components/Details/DetailsVideo';
import LoginForm from './components/Login/LoginForm';
import SignUpForm from './components/SignUp/SignUpForm';

import AssetVideoPlayer from './components/VideoPlayer/AssetVideoPlayer';
import Profile from './components/Profile/Profile';
import AboutUs from './components/AboutUs/AboutUs';

// react date time range css
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

// toastify
import 'react-toastify/dist/ReactToastify.css';
import 'react-multi-carousel/lib/styles.css';

function App() {
  // Bring stateholders from context
  const {
    setAllCategories,

    setKey,

    setOrganizationId,

    setLanguage,
    isResponsiveclose,
    user,
    setUser,
  } = useMyContext();
  // organizationSecret
  const [cookies, setCookie] = useCookies(['osec']);
  const { t } = useTranslation();

  /**** STEP 1, INIT PROGRAM ****/
  useEffect(() => {
    async function initProgram() {
      try {
        // TODO: Import from suite, now importing just from settings.json

        // Get organization data (id and key)
        const getOrganization = settings.organization;

        // Set organizationId to context
        setOrganizationId(getOrganization.organizationId);

        // Set key to context
        setKey(getOrganization.key);

        // Get language from settings
        const getLanguage = settings.language;

        // Set language to context
        setLanguage(getLanguage);

        // Get categories from datahandler
        const res = await getCategories(
          getOrganization.organizationId,
          getOrganization.key,
          getLanguage
        );

        setAllCategories(res);
        setCookie('osec', getOrganization.key, { path: '/' });
        // If there's existing userToken in cookies
        if (cookies?.userData?.userToken) {
          // Get user from context and copy it to modifiedUser
          let modifiedUser = { ...user };

          // Change status of user as logged in
          modifiedUser.loggedIn = true;

          // Set userToken from cookies
          modifiedUser.userId = cookies?.userData?.userId;

          // Set userToken from cookies
          modifiedUser.userToken = cookies?.userData?.userToken;

          // Update user in context, with modified data
          setUser(modifiedUser);
        }
      } catch (err) {
        console.log(err);
      }
    }

    initProgram();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className='App'>
      <div className='container'>
        <NavBar
          links={
            <>
              <div className={classes.navBarRow_primary}>
                <NavBarButton route={settings.routes.events} name={'Events'} />
                <NavBarButton
                  route={`${settings.routes.channels}/${settings.organization.organizationId}`}
                  name={'Channels'}
                />

                <NavBarCategoryDropDown
                  styles={styles}
                  route={settings.routes.svodCategoriesRoute}
                />
                <NavBarButton
                  route={settings.routes.contactus}
                  name={'Contact Us'}
                />
              </div>
              <div className={classes.navRight}>
                {' '}
                {/* // show only in bigger screen */}
                {!isResponsiveclose && (
                  <>
                    {/* <LanguageSelect1 />{' '} */}
                    <NavBarButton
                      route={settings.routes.search}
                      icon='FaSearch'
                    />
                  </>
                )}
                <LoginBTN routes={settings.routes} />
                {!user?.loggedIn && (
                  <LoginBTN routes={settings.routes} signUp />
                )}
              </div>
            </>
          }
          // small screen
          linksMobileLogin={
            <div className={classes.navRight}>
              <>
                <LoginBTN
                  routes={settings.routes}
                  hideLogoutSmallScreen={true}
                />
                <LoginBTN
                  routes={settings.routes}
                  signUp
                  hideLogoutSmallScreen={true}
                />
              </>
            </div>
          }
          linksMobileSearch={
            <div className={classes.navRight}>
              <NavBarButton route={settings.routes.search} icon='FaSearch' />
            </div>
          }
        />
        <Switch>
          <Route path='/' exact>
            <Banner
              settings={settings.components.frontPageBanner_01}
              styles={styles}
            />
            <Promo
              settings={settings.components.frontPagePromo_01}
              styles={styles}
              hideDescription={true}
            />
            <div style={{ background: '#ddd', paddingBottom: '20px' }}>
              <div style={{ width: '88%', margin: '0 auto' }}>
                <LiveNowEvents
                  organizationId={settings.organization.organizationId}
                  homepageContainer={true}
                />
                <UpcomingEvents
                  organizationId={settings.organization.organizationId}
                  settings={settings.components.frontPageUpcomingEvents}
                  homepageContainer={true}
                />
              </div>
            </div>
            {/* // Something to Watch */}
            {/* <ViewedSection
              styles={styles}
              settings={settings.components.viewedSection_01}
              organizationId={settings.organization.organizationId}
            />
            <EditorChoice
              styles={styles}
              settings={settings.components.editorChoiceVideo}
              organizationId={settings.organization.organizationId}
            /> */}

            {/* <div style={{ background: '#ebf4fb' }}> */}
            <div style={{ paddingBottom: '20px' }}>
              <div className='homePageContainer'>
                <SeriesAsset
                  settings={settings.components.frontPageCategories_01}
                  showDuration={false}
                  showReleaseYear={true}
                  showPublishedDate={true}
                  hideDescription={true}
                  latestData={true}
                  titleName={t('seriesSlider.latest')}
                />
                <SeriesAsset
                  settings={settings.components.frontPageCategories_01}
                  showDuration={false}
                  showReleaseYear={true}
                  showPublishedDate={true}
                  hideDescription={true}
                  titleName={t('seriesSlider.trending')}
                />
                <RenderCategoriesWithTitles
                  settings={settings.components.frontPageCategories_02}
                  styles={styles}
                  imageType={'thumbnailSerie'}
                  showDuration={false}
                  showReleaseYear={true}
                  showPublishedDate={true}
                  hideDescription={true}
                />
              </div>
            </div>
          </Route>

          <Route
            path={`/${settings.routes.packages}/:orgId/:itemType?/:itemId?`}
            exact
          >
            <PurchaseFlow settings={settings.components.purchaseflow} />
          </Route>

          <Route path={`/${settings.routes.svodCategoriesRoute}/:asset`} exact>
            <RenderCategoryDropdown
              styles={styles}
              route={settings?.routes.svodCategoriesRoute}
            />
            <ChosenCategoryGrid
              styles={styles}
              settings={settings.components.svodChosenCategoryGrid_01}
              imageType={'thumbnailSerie'}
              showDuration={false}
              showReleaseYear={true}
              showPublishedDate={true}
              hideDescription={true}
            />

            {/* <CategoryContentSimiliar
              settings={settings.components.svodSimiliar_01}
              styles={styles}
            /> */}
          </Route>
          <Route path={`/${settings.routes.tos}`}>
            <TOS />
          </Route>
          <Route path={`/${settings.routes.privacyPolicy}`}>
            <PrivacyPolicy />
          </Route>

          {/* Single Event page which is embedded from suite atm */}

          <Route path={`/${settings.routes.upcomingEvent}`} exact>
            <UpcomingEvent routes={settings.routes} />
          </Route>

          {/* We get Events based on categoryId and show them to user */}

          <Route path={`/${settings.routes.eventBasedOnCategory}`} exact>
            <div style={{ background: '#ddd', paddingBottom: '20px' }}>
              <div style={{ width: '88%', margin: '0 auto' }}>
                <EventsCategoryItem
                  settings={settings.components.EventsCategoryItem}
                />
              </div>
            </div>
          </Route>
          {/* Events main page */}

          <Route path={`/${settings.routes.events}`} exact>
            <div style={{ background: '#ddd', paddingBottom: '20px' }}>
              <div style={{ width: '88%', margin: '0 auto' }}>
                <LiveNowEvents
                  organizationId={settings.organization.organizationId}
                />
                <UpcomingEvents
                  organizationId={settings.organization.organizationId}
                  settings={settings.components.events}
                />
              </div>
              {/* <EventsEditorChoice
                settings={settings.components.EventsEditorChoice}
                categoryId={settings.components.events.editorChoiceCategoryId}
                limit='3'
                organizationId={settings.organization.organizationId}
              /> */}
              <div style={{ width: '88%', margin: '0 auto' }}>
                <EventsCategory
                  organizationId={settings.organization.organizationId}
                  settings={settings.components.events}
                />
              </div>
            </div>
          </Route>
          <Route path={`/${settings.routes.profile}`}>
            <Profile settings={settings.components.profile} />
          </Route>

          <Route path={`/${settings.routes.login}`}>
            <LoginForm routes={settings.routes} />
          </Route>

          {/* ROUTE FOR SIGNUP VIEW*/}

          <Route path={`/${settings.routes.signUp}`}>
            <SignUpForm
              routes={settings.routes}
              settings={settings.components.signUp}
              fields={settings.components.signUp.fields}
            />
          </Route>
          {/* Channels main page */}
          <Route path='/channels/:orgId' exact>
            <ChannelsMainPage />
          </Route>

          {/* about us */}
          <Route path={`/${settings.routes.aboutus}`}>
            <AboutUs />
          </Route>
          <Route path={`/${settings.routes.pricing}`}>
            <Pricing />
          </Route>
          {/* channel Single page */}
          <Route path='/channels/:orgId/:subOrganizationId/:links?' exact>
            <Channel routes={settings.routes} />
          </Route>

          <Route path={`/${settings.routes.videoRoute}/:orgId/:asset`} exact>
            <AssetVideoPlayer backRoute={'/'} playerAutoStart={true} />

            <DetailsVideo
              styles={settings.styles}
              routes={settings.routes}
              hideBanner={true}
              hideInfoData={true}
              showPublishedDate={true}
            />
            {/* <CategorySimiliar
              settings={settings.components.svodSimiliar_01}
              styles={styles}
            /> */}
          </Route>
          <Route path={`/${settings.routes.svodSerieRoute}/:asset`} exact>
            <DetailsSerie styles={settings.styles} />
          </Route>
        </Switch>
        <Route path={`/${settings.routes.contactus}`}>
          <ContactUs />
        </Route>
        {/* ROUTE FOR SEARCH VIEW*/}
        <Route path={`/${settings.routes.search}`}>
          <Search settings={settings.components.search_01} />
        </Route>

        <BottomBar routes={settings.routes} />
      </div>
    </div>
  );
}

export default App;

import React, { useEffect, useState } from 'react';
import { NavLink, useParams, useHistory } from 'react-router-dom';
import { useMyContext } from '../../../contexts/StateHolder';
// import HeroBanner from '../../Hero/HeroBanner';
import * as classes from '../ChannelsMainPage.module.css';
import Loader from 'react-loader-spinner';
import * as settings from '../../../config_settings.json';
import {
  findSubOrganizationSecret,
  getAssets,
  getCategories,
  getChannels,
} from '../../../scripts/dataHandlers';
import { createGroupItemId, createToken } from '../../../scripts/utils';
import config from '../../../config_settings.json';
import ChannelVideos from './ChannelVideos';
import ChannelEvents from './ChannelEvents';
import { useCookies } from 'react-cookie';
import RenderBanner2 from '../../ViewAssets/Sliders/RenderBanner1/RenderBanner2';

export default function Channel(props) {
  const { subOrganizationDetails } = useMyContext();
  const [subOrganizationAllAssets, setSubOrganizationAllAssets] =
    useState(null);
  const [organizationLoading, setOrganizationLoading] = useState(true);
  const { orgId, subOrganizationId, links } = useParams();

  const [secret, setSecret] = useState(null);

  // saving subOrgannozationDetail locally
  const [channelInfo, setChannelInfo] = useState(null);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getLanguage = config.language;

    const runFunction = async () => {
      let res1;
      if (!subOrganizationDetails) {
        res1 = await getChannels(subOrganizationId, secret);
        console.log(res1);
        setChannelInfo(res1.data.organization);
      } else {
        setChannelInfo(subOrganizationDetails);
      }
      const allCategories = await getCategories(
        subOrganizationId,
        secret,
        getLanguage
      );
      // let allSubCategoriesId =
      //   allCategories.length > 0 && allCategories.map((el) => el.id);
      let allSubCategoriesId = createGroupItemId(allCategories);
      console.log(secret, subOrganizationId, allSubCategoriesId);

      const res = await getAssets(
        subOrganizationId,
        allSubCategoriesId || '',

        createToken(subOrganizationId, allSubCategoriesId, secret),

        getLanguage
      );
      console.log(res);
      setSubOrganizationAllAssets(res);
      setOrganizationLoading(false);
      setLoading(false);
    };
    secret && getLanguage && runFunction();
  }, [secret]);

  useEffect(() => {
    if (orgId * 1 !== subOrganizationId * 1) {
      setLoading(true);
      const getAllSubOrganization = async () => {
        let subKey = await findSubOrganizationSecret(
          orgId,
          config.organization.key,
          subOrganizationId
        );

        console.log('subKey', subKey);

        setSecret(subKey?.secret);
        setLoading(false);
      };

      getAllSubOrganization();
    } else {
      setSecret(config.organization.key);
    }
  }, [subOrganizationId, orgId]);

  console.log('secret', secret);

  return channelInfo ? (
    <>
      <RenderBanner2
        HeroSecondaryHide={true}
        HeroPrimaryDetailsHide={true}
        image={'channelBannerDemo'}
        style={{
          width: '100vw',
          margin: '0 auto',
          padding: '0',
          borderRadius: 0,
          minHeight: '350px',
        }}
        imageBorderNone={true}
        bannerTitle={channelInfo && channelInfo.name && channelInfo.name}
      />

      <div className={`${classes.channelsLink} font-400`}>
        <NavLink
          to={`/channels/${orgId}/${subOrganizationId}`}
          exact
          activeClassName={classes.active}
        >
          Videos
        </NavLink>
        <NavLink
          exact
          to={`/channels/${orgId}/${subOrganizationId}/event`}
          activeClassName={classes.active}
        >
          Events
        </NavLink>
      </div>

      {!links && !organizationLoading && (
        <ChannelVideos
          videos={subOrganizationAllAssets}
          organizationId={subOrganizationId}
          routes={props.routes}
        />
      )}

      {!loading && links === 'event' && (
        <ChannelEvents
          organizationId={subOrganizationId}
          settings={settings.components.frontPageUpcomingEvents}
        />
      )}
    </>
  ) : (
    <div className='display-flex-center'>
      {' '}
      <Loader type='TailSpin' color='#ab0303' />{' '}
    </div>
  );
}

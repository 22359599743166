import { useMyContext } from '../../contexts/StateHolder';
import { useEffect, useState } from 'react';
import settings from '../../config_settings.json';
import { useCookies } from 'react-cookie';
import { useMyContextFunctions } from '../../contexts/ContextFunctions';
import { useParams } from 'react-router';
import { findSubOrganizationSecret } from '../../scripts/dataHandlers';
import LiveEventPlayer from './LiveEventPlayer';

const LiveVideoPlayer = (props) => {
  console.log('props', props);
  // Bring stateholders from context
  const { key, organizationId, liveChannelPrivate } = useMyContext();

  const [channelServiceId, setChannelServiceId] = useState(null);
  const [groupId, setGroupId] = useState(null);
  const [companyId, setCompanyId] = useState(null);
  const [organizationID, setOrganizationID] = useState(null);
  const [secretKey, setSecretKey] = useState(null);

  const { redirectUserToLogin } = useMyContextFunctions();
  const [cookies] = useCookies('');
  console.log(cookies);

  const { orgId } = useParams();

  useEffect(() => {
    // In case of live event player ->if the event belongs to the child organization and in the main page we always try to view the stream from parent organization->it might not work in this case ->cause we are passing secret and organiation of parent org instead of child ->here now in case of beta we do receive groupId and we can use this group id to find the organizationId
    if (organizationId) {
      // we get groupId from event object and we need to find organizationId from there

      let configGroupId = settings.organization.groupId * 1;

      if (props?.event) {
        console.log('event', props.event);
        console.log(props?.event?.groupId * 1 === configGroupId);

        if (props?.event?.groupId * 1 === configGroupId) {
          // this means the event belong to parent organization
          setGroupId(settings.organization.groupId);
          setOrganizationID(organizationId);
          setSecretKey(key);
        } else {
          // this event does not belong to parent organization
          setGroupId(props?.event?.groupId * 1);
          setOrganizationID(props?.event?.groupId * 1 - 1);

          const getAllSubOrganization = async () => {
            let subKey = await findSubOrganizationSecret(
              organizationId,
              key,
              props?.event?.groupId * 1 - 1
            );
            console.log('subKey', subKey);

            setSecretKey(subKey?.secret);
          };
          getAllSubOrganization();
        }
        setChannelServiceId(
          props.channelServiceId || settings.organization.channelServiceId
        );
        setCompanyId(settings.organization.companyId);
      } else {
        // just assuming we dont get groupId from API  and this will fall if the event does not belong to parent org
        setGroupId(orgId ? orgId * 1 + 1 : settings.organization.groupId);
        setChannelServiceId(
          props.channelServiceId || settings.organization.channelServiceId
        );
        setCompanyId(settings.organization.companyId);
        setOrganizationID(orgId ? orgId : organizationId);
        if (orgId * 1 !== organizationId * 1) {
          const getAllSubOrganization = async () => {
            let subKey = await findSubOrganizationSecret(
              organizationId,
              key,
              orgId
            );

            setSecretKey(subKey?.secret);
          };
          getAllSubOrganization();
        } else {
          setSecretKey(key);
        }
      }
    }
  }, [organizationId, props.channelServiceId, orgId, key]);
  useEffect(() => {
    if (liveChannelPrivate) {
      !cookies.userData.userId && redirectUserToLogin('login');
    }
  }, [redirectUserToLogin, cookies.userData, liveChannelPrivate]);

  return key && channelServiceId && companyId ? (
    <div
      className={
        props.showInAdminpage ? 'smallContainerprimary' : 'maxContainerPrimary'
      }
    >
      {/* // live player event is for live player with event -> digital is lil differnt cause that is not an event */}
      <LiveEventPlayer
        companyId={companyId}
        groupId={groupId} // Group of organization
        organizationId={organizationID} // Id of organization
        playerAutoStart={props.playerAutoStart}
        secretKey={secretKey} // Secret key for organization
        channelServiceId={channelServiceId} // channelServiceId for live video
        backRoute={props.backRoute} // Route, where back button takes user. If undefined, it takes automatically to previous route
        //key={assetId} // For react's re-rendering purposes
        userId={liveChannelPrivate ? cookies.userData.userId : 0}
        // to check if the live player is playing live events , we pass as true if it  is playing live events ffor sending different analytics
        eventPlayer={props.eventPlayer}
        eventId={props.eventId}
      />
    </div>
  ) : null;
};

export default LiveVideoPlayer;

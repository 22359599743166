import React from 'react';
import { getImageByKey } from '../../scripts/getImageByKey';
import * as classes from './ContactUs.module.css';

export default function ContactUs() {
  return (
    <div className={classes.ContactUs}>
      <div className={classes.ContactUsPrimary}>
        <h4 className='font-700'>Contact Us</h4>

        <div className={classes.ContactUs_image}>
          <img src={getImageByKey('contactus')} alt='Digital Tree' />
        </div>
        <div className={classes.ContactUs_description_Primary}>
          <div className={classes.ContactUs_description_address}></div>

          <div className={classes.ContactUs_description_email}>
            <a
              className={classes.ContactUs_description_email_button}
              href='mailto:david@dtmedia.com.na
'
            >
              Send Email
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

import React from 'react';
import Header from '../../../shared/Header/Header';
import * as classes from './UpcomingEvent.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { convertDuration, momentDate } from '../../../scripts/utils';
import moment from 'moment';
import RenderFolderNames from '../../Details/DetailsFolderNames';
import { useTranslation } from 'react-i18next';
import { BuyTickets, eventBackground } from './UpcomingEvent';

export default function EventsRoomDescription({
  ticketAccessGranted,
  singleEvent,
  ticketAccess,
  liveEvent,
  defaultLanguage,
  setTicketAccessGranted,
  routes,
  futureEvents,
}) {
  const { t } = useTranslation();

  // if access is granted we show room info else event info
  console.log(singleEvent);

  const singleItem = ticketAccessGranted ? singleEvent : singleEvent;
  return (
    <div className={classes.EventTertairy}>
      <div className={classes.EventPrimary}>
        <Header
          extraClassname={true}
          title={
            singleEvent?.publicName[defaultLanguage] ||
            singleEvent?.name[defaultLanguage]
          }
        />
        {ticketAccess && !ticketAccessGranted && (
          <div style={{ position: 'relative' }}>
            {singleEvent?.thumbnailImage && eventBackground(singleEvent)}{' '}
            {liveEvent && (
              <div
                className={classes.EventPrimary_countdown1}
                style={{
                  opacity: '1 !important',
                  position: 'absolute',
                  top: '15px',
                  color: 'white',
                }}
              >
                {' '}
                <div className={`${classes.EventPrimary_countdown} font-600`}>
                  {t('eventsCategory.eventStarted')}
                </div>
                <div className={classes.EventTimeDescription}>
                  <div className={`${classes.EventPrimary_date} font-300`}>
                    <div>
                      <FontAwesomeIcon icon='calendar' />
                    </div>
                    <div>{momentDate(singleItem?.startTimePublic)}</div>
                  </div>
                  <div className={`${classes.EventPrimary_time} font-300`}>
                    <div>
                      {' '}
                      <FontAwesomeIcon icon='clock' />
                    </div>
                    <div>
                      {moment(singleItem?.startTimePublic)
                        .locale('fi')
                        .format('LT')}
                    </div>{' '}
                  </div>
                  <div className={`${classes.EventPrimary_time} font-300`}>
                    <div>
                      {' '}
                      <FontAwesomeIcon icon='hourglass-start' />
                    </div>
                    <div>{convertDuration(singleItem?.durationPublic)}</div>{' '}
                  </div>
                </div>
                <RenderFolderNames item={singleEvent.categories} />
                {ticketAccess && !ticketAccessGranted && (
                  <BuyTickets
                    routes={routes}
                    futureEvents={futureEvents}
                    singleEvent={singleEvent}
                    setTicketAccessGranted={setTicketAccessGranted}
                  />
                )}
              </div>
            )}
          </div>
        )}

        <div
          className={classes.EventPrimary_description}
          dangerouslySetInnerHTML={{
            __html: singleItem?.description[defaultLanguage],
          }}
        ></div>
      </div>
    </div>
  );
}

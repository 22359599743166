import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { useEffect, useState, useCallback } from 'react';
import Countdown, { zeroPad } from 'react-countdown';
import { useMyContext } from '../../../contexts/StateHolder';

import * as classes from './UpcomingEvent.module.css';
import { useLocation, useParams } from 'react-router-dom';
import {
  checkTicketNumber,
  getSingleEvent,
  getSingleGame,
  purchasePackage,
} from '../../../scripts/dataHandlers';
import AssetVideoPlayer from '../../VideoPlayer/AssetVideoPlayer';
import { useHistory } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import queryString from 'query-string';
import { ToastContainer, toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import 'moment/locale/fi';
import LiveEvents from '../LiveEvents/LiveEvents';
import EventsRoomDescription from './EventsRoomDescription';
import { momentDate } from '../../../scripts/utils';
import Header from '../../../shared/Header/Header';
import Button from '../../../shared/Button/Button';
import CompletedEventWithRecording from './CompletedEventWithRecording';
export const eventBackground = (el) => {
  return (
    <div className='overlay_blur bannerImage' style={{ position: 'relative' }}>
      {' '}
      <div
        className={`${classes.EventSecondary} ${classes.EventSecondary_backgroundImage} `}
        style={{
          background: `url(${el?.thumbnailImage}) `,

          height: '500px',
          width: '100%',
          display: 'flex',
          alignItems: 'flex-end',
          opacity: '0.5',
        }}
      ></div>
    </div>
  );
};

export const BuyTickets = ({
  routes,
  futureEvents,
  singleEvent,
  setTicketAccessGranted,
}) => {
  const history = useHistory();
  const { t } = useTranslation();

  const [ticketNum, setTicketNum] = useState('');
  const [cookies, setCookie, removeCookie] = useCookies(['tiAcc']);
  const location = useLocation();

  const buyNewTicket = () => {
    console.log('singleEvent', singleEvent);
    // we need to send also organizationId to this packages

    setCookie(
      'packageReturn',
      { returnPath: location.pathname },
      { path: '/' }
    );
    removeCookie('packageType', {
      path: '/',
    });
    setTimeout(() => {
      setCookie('packageType', 'ticket', {
        path: '/',
      });
      return history.push(
        `/${routes.packages}/${singleEvent?.groupId - 1}/event/${
          singleEvent.eventId
        }`
      );
    }, 100);
  };

  const checkTicketHandler = async () => {
    const res = await checkTicketNumber(singleEvent.eventId, ticketNum);
    console.log(res);
    if (res.data.status === 'ok') {
      setTicketAccessGranted(true);
      setCookie('tiAcc', ticketNum, { path: '/' });
    } else if (res.data.status === 'error') {
      setTicketNum('');
      removeCookie('tiAcc', { path: '/' });

      if (futureEvents) {
        return toast.error(`${t('eventsCategory.eventNotStarted')}`, {
          autoClose: 9000,
          position: 'top-right',
          closeOnClick: true,
          draggable: true,

          hideProgressBar: true,

          theme: 'colored',
        });
      }
      return toast.error(`${t('eventsCategory.invalidTicket')}`, {
        autoClose: 9000,
        position: 'top-right',
        closeOnClick: true,
        draggable: true,

        hideProgressBar: true,

        theme: 'colored',
      });
    }
  };

  return (
    <>
      <ToastContainer />

      <div className={classes.buyTicket}>
        <div className={classes.buyTickets_info}>
          <div>{t('eventsCategory.enterTicket')}</div>

          <div className={classes.buyTickets_info_input}>
            <input
              type='text'
              value={ticketNum}
              onChange={(e) => setTicketNum(e.target.value)}
              placeholder={`${t('eventsCategory.enterYourTicket')}`}
            />
          </div>
        </div>

        <div className={classes.buyTickets_info_button}>
          <Button onClick={checkTicketHandler} inverse={true}>
            {' '}
            {t('eventsCategory.watch')}
          </Button>

          <Button onClick={buyNewTicket}>
            {t('eventsCategory.buyTickets')}
          </Button>
        </div>
      </div>
    </>
  );
};

export default function UpcomingEvent(props) {
  console.log(props);
  const { t } = useTranslation();

  const [singleEvent, setSingleEvent] = useState(null);
  const [defaultLanguage, setDefaultLanguage] = useState(null);
  const [loading, setLoading] = useState(true);
  const [recordingAssetId, setRecordingAssetId] = useState(null);

  const [isLiveEvents, setIsLiveEvents] = useState(false);
  const { singleEventItem, setTicketQuantity } = useMyContext();
  const { orgId, eventId } = useParams();
  const { user, setGoBackToPrevious } = useMyContext();

  // if the events requires ticket to grant access

  const [ticketAccess, setTicketAccess] = useState(false);
  const [ticketAccessGranted, setTicketAccessGranted] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(['tiAcc']);
  const history = useHistory();

  // if ticccket has been checked already so we can give error /succcesful message

  // this useEffect will only run when user is redirected from purchase page to here

  // useEffect(() => {
  //   // we need to check for login if it does not exists we will redirect to home page
  //   console.log('user', user);
  //   if (!user?.loggedIn) {
  //     setGoBackToPrevious(true);
  //     history.push('/login');
  //   }
  // }, [user]);

  useEffect(() => {
    let params = queryString.parse(window.location.search);

    removeCookie('packageType', { path: '/' });

    async function purchasePackageData() {
      const purchaseResponse = await purchasePackage(
        cookies?.userData?.userToken,
        params
      );
      console.log(purchaseResponse, 'purchaseResponse');
      let ticket;

      console.log(
        purchaseResponse,
        purchaseResponse.message,
        purchaseResponse.errorCode === 27,
        purchaseResponse.errorCode
      );
      if (
        purchaseResponse.message.includes('successful') ||
        purchaseResponse.status === 'ok'
      ) {
        setTicketAccessGranted(true);
        ticket = purchaseResponse?.tickets[0];
        console.log('ticketnew', ticket);

        toast.success('Purchase Successful');
        ticket && setCookie('tiAcc', ticket, { path: '/' });
      } else if (
        purchaseResponse.errorCode === 27 ||
        purchaseResponse.message.includes('refresh call for order')

        // this occours when user refrsh the page
      ) {
        ticket = purchaseResponse?.tickets[0];
        console.log('ticketrefreh', ticket);
        ticket && setCookie('tiAcc', ticket, { path: '/' });
      } else {
      }

      setLoading(false);
    }
    console.log(
      params,
      'params',
      params?.RETURN_CODE,
      params?.RETURN_CODE === true
    );

    // for dpo payment
    if (params) {
      if (params?.paymentMethod === '7') {
        purchasePackageData();
      } else if (params?.RETURN_CODE === '0') {
        purchasePackageData();
      } else {
        setLoading(false);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // checks ticket
  useEffect(() => {
    if (singleEventItem) {
      setSingleEvent(singleEventItem);
      setDefaultLanguage(singleEventItem.defaultLanguage || 'en_US');
      setLoading(false);
      if (singleEventItem?.accessControls?.length > 0) {
        const ticketExists = singleEventItem?.accessControls.find(
          (el) => el.typeName === 'Ticket'
        );
        console.log('ticketExists', ticketExists);
        if (ticketExists) {
          // not granted access for viewing event
          setTicketAccess(true);
        } else {
          setTicketAccessGranted(true);
          setTicketAccess(false);
        }
      } else {
        setTicketAccess(false);
        setTicketAccessGranted(true);
      }
    } else {
      const getData = async () => {
        const res = await getSingleEvent(orgId, eventId);

        // const res1 = await getSingleGamePublicUs();
        // we also need to game
        console.log('res', res);
        setSingleEvent(res?.event);
        setDefaultLanguage(res?.event?.defaultLanguage);
        if (res?.event?.accessControls?.length > 0) {
          const ticketExists = res?.event?.accessControls.find(
            (el) => el.typeName === 'Ticket'
          );
          console.log('ticketExists', ticketExists);

          if (ticketExists) {
            setTicketAccess(true);
          } else {
            setTicketAccessGranted(true);
            setTicketAccess(false);
          }
        } else {
          setTicketAccess(false);
          setTicketAccessGranted(true);
        }

        setLoading(false);
      };
      getData();
    }
    return () => {
      setTicketQuantity(1);
    };
  }, [singleEventItem]);

  const checkAcessFromCookie = useCallback(async () => {
    const res = await checkTicketNumber(singleEvent?.eventId, cookies?.tiAcc);
    if (res.data.status === 'ok') {
      setTicketAccessGranted(true);
      setCookie('tiAcc', cookies?.tiAcc, { path: '/' });
    } else {
      setTicketAccessGranted(false);
      removeCookie('tiAcc', { path: '/' });
    }
  }, [
    singleEvent?.eventId,
    cookies?.tiAcc,
    setTicketAccessGranted,
    setCookie,
    removeCookie,
  ]);

  useEffect(() => {
    // we will have ticket in our cookie all the time && loading false means if the ticket was just bought here and redirected heree it means that function will run first
    if (singleEvent?.eventId && cookies?.tiAcc) {
      checkAcessFromCookie();
    }
  }, [cookies?.tiAcc, singleEvent?.eventId]);

  console.log(ticketAccess, ticketAccessGranted, 'ticket');

  // const CompletedEventsWithRecording = ({ routes }) => {
  //   const [completedRecordingAssetId, setCompletedRecordingAssetId] =
  //     useState(null);

  //   useEffect(() => {
  //     if (ticketAccessGranted) {
  //       const checkForVideoAccess = async () => {
  //         const res = await checkTicketNumber(
  //           singleEvent?.eventId,
  //           cookies?.tiAcc
  //         );
  //         if (res.data.status === 'ok') {
  //           setTicketAccessGranted(true);
  //           setCookie('tiAcc', cookies?.tiAcc, { path: '/' });
  //           setCompletedRecordingAssetId(
  //             singleEvent.rooms[0].recordings[0]?.assetId
  //           );
  //         } else {
  //           setTicketAccessGranted(false);
  //           removeCookie('tiAcc', { path: '/' });
  //         }
  //       };
  //       checkForVideoAccess();
  //     }
  //   }, [completedRecordingAssetId]);

  //   return (
  //     <>
  //       {/* // this will work when there is access control applied to recording , wee have prevented a loophole by passing completedRecordingAssetId as a parameter */}
  //       {ticketAccessGranted &&
  //         ticketAccess &&
  //         completedRecordingAssetId &&
  //         cookies?.tiAcc && (
  //           <div className={classes.SecondaryContainer}>
  //             <AssetVideoPlayer
  //               asset={completedRecordingAssetId}
  //               isEvent={true}
  //             />
  //           </div>
  //         )}
  //       {/* // will work when no access is applied */}
  //       {ticketAccessGranted && !ticketAccess && (
  //         <div className={classes.SecondaryContainer}>
  //           <AssetVideoPlayer asset={recordingAssetId} isEvent={true} />
  //         </div>
  //       )}

  //       <EventsRoomDescription
  //         liveEvent={isLiveEvents}
  //         routes={routes}
  //         ticketAccessGranted={ticketAccessGranted}
  //         singleEvent={singleEvent}
  //         ticketAccess={ticketAccess}
  //         defaultLanguage={defaultLanguage}
  //         setTicketAccessGranted={setTicketAccessGranted}
  //         futureEvents={false}
  //       />
  //     </>
  //   );
  // };
  const CompletedEventsWithoutRecording = () => {
    return (
      <div className={classes.EventTertairy}>
        <div className={classes.EventPrimary}>
          <Header
            extraClassname={true}
            title={
              singleEvent?.publicName[defaultLanguage] ||
              singleEvent?.name[defaultLanguage]
            }
          />
          <div style={{ position: 'relative' }}>
            {singleEvent?.thumbnailImage && eventBackground(singleEvent)}

            <div
              style={{
                opacity: '1 !important',
                position: 'absolute',
                top: '75px',
                color: 'white',
              }}
              className={classes.EventPrimary_countdown1}
            >
              <div className={`${classes.EventPrimary_countdown} font-600`}>
                {t('eventsCategory.eventsEnded')}
              </div>
              <div className={classes.EventTimeDescription}>
                <div className={`${classes.EventPrimary_date} font-300`}>
                  <div>
                    <FontAwesomeIcon icon='calendar' />
                  </div>

                  <div>{momentDate(singleEvent?.startTimePublic)}</div>
                </div>
                <div className={`${classes.EventPrimary_time} font-300`}>
                  <div>
                    {' '}
                    <FontAwesomeIcon icon='clock' />
                  </div>
                  <div>
                    {moment(singleEvent.startTimePublic)
                      .locale('fi')
                      .format('LT')}
                  </div>{' '}
                </div>
              </div>
            </div>
          </div>

          <div
            className={classes.EventPrimary_description}
            dangerouslySetInnerHTML={{
              __html: singleEvent.description[defaultLanguage],
            }}
          ></div>
        </div>
      </div>
    );
  };

  const CompletedEvents = ({ routes }) => {
    useEffect(() => {
      if (singleEvent?.rooms?.length > 0) {
        setRecordingAssetId(singleEvent.rooms[0].recordings[0]?.assetId);
        setIsLiveEvents(false);
      }

      let currentTime = Date.now();
      // time is second, Dat.now is ms so multiply by 1000
      let totalEndTime;

      if (singleEvent?.startTimePublic) {
        totalEndTime =
          singleEvent?.startTimePublic + singleEvent?.durationPublic * 1000;
      } else {
        totalEndTime = singleEvent?.startTime + singleEvent?.duration * 1000;
      }

      if (
        currentTime >= singleEvent.startTimePublic
          ? singleEvent.startTimePublic
          : singleEvent.startTime && currentTime < totalEndTime
      ) {
        setIsLiveEvents(true);
      }
    }, []);
    console.log('isLiveEvents', isLiveEvents, singleEvent, recordingAssetId);
    if (isLiveEvents)
      return (
        <LiveEvents
          routes={routes}
          ticketAccess={ticketAccess}
          ticketAccessGranted={ticketAccessGranted}
          singleEvent={singleEvent}
          defaultLanguage={defaultLanguage}
          setTicketAccessGranted={setTicketAccessGranted}
        />
      );

    return recordingAssetId ? (
      <CompletedEventWithRecording
        routes={routes}
        ticketAccess={ticketAccess}
        ticketAccessGranted={ticketAccessGranted}
        singleEvent={singleEvent}
        defaultLanguage={defaultLanguage}
        setTicketAccessGranted={setTicketAccessGranted}
        loading={loading}
        recordingAssetId={recordingAssetId}
        isLiveEvents={isLiveEvents}
        setLoading={setLoading}
      />
    ) : (
      <CompletedEventsWithoutRecording routes={routes} />
    );
  };

  const FutureEvents = ({
    days,
    hours,
    minutes,
    seconds,
    completed,
    routes,
  }) => {
    return (
      <>
        <div className={classes.EventSecondary}>
          <Header
            extraClassname={true}
            title={
              singleEvent?.publicName[defaultLanguage] ||
              singleEvent?.name[defaultLanguage]
            }
          />
        </div>
        <div
          className={`${classes.EventTertairy} `}
          style={{ position: 'relative' }}
        >
          {singleEvent?.thumbnailImage && eventBackground(singleEvent)}

          <div className={classes.EventPrimary}>
            <div
              style={{
                opacity: '1 !important',
                position: 'absolute',
                top: '15px',
              }}
            >
              {' '}
              <div
                className={`${classes.EventPrimary_countdown} font-800`}
                // style={{
                //   opacity: '1 !important',
                //   position: 'absolute',
                //   top: '75px',
                // }}
              >
                <div className={classes.EventPrimary_countdown_description}>
                  {' '}
                  <span>{zeroPad(days)}</span>
                  <p className='font-200' style={{ color: 'white' }}>
                    {t('eventsCategory.days')}
                  </p>
                </div>
                <div className={classes.EventPrimary_countdown_description}>
                  <span>{zeroPad(hours)}</span>
                  <p className='font-200' style={{ color: 'white' }}>
                    {t('eventsCategory.hours')}
                  </p>
                </div>
                <div className={classes.EventPrimary_countdown_description}>
                  <span>{zeroPad(minutes)}</span>

                  <p className='font-200' style={{ color: 'white' }}>
                    {t('eventsCategory.minutes')}
                  </p>
                </div>
                <div className={classes.EventPrimary_countdown_description}>
                  <span>{zeroPad(seconds)}</span>

                  <p className='font-200' style={{ color: 'white' }}>
                    {t('eventsCategory.seconds')}
                  </p>
                </div>
              </div>
              <div className={classes.EventTimeDescription}>
                <div className={`${classes.EventPrimary_date} font-200`}>
                  <div>
                    <FontAwesomeIcon icon='calendar' />
                  </div>

                  <div>{momentDate(singleEvent?.startTimePublic)}</div>
                </div>
                <div className={`${classes.EventPrimary_time} font-200`}>
                  <div>
                    {' '}
                    <FontAwesomeIcon icon='clock' />
                  </div>
                  <div>
                    {moment(singleEvent.startTimePublic)
                      .locale('fi')
                      .format('LT')}
                  </div>{' '}
                </div>
              </div>
              {ticketAccess && !ticketAccessGranted && (
                <BuyTickets
                  routes={routes}
                  futureEvents={true}
                  singleEvent={singleEvent}
                  setTicketAccessGranted={setTicketAccessGranted}
                />
              )}
              {ticketAccess && ticketAccessGranted && (
                <div className={`${classes.EventPrimary_countdown} font-600`}>
                  {t('eventsCategory.eventNotStarted')}:<br />
                </div>
              )}
            </div>

            <div
              className={classes.EventPrimary_description}
              dangerouslySetInnerHTML={{
                __html: singleEvent.description[defaultLanguage],
              }}
            ></div>
          </div>
        </div>
      </>
    );
  };

  const Renderer = ({ days, hours, minutes, seconds, completed }) => {
    console.log(days, 'days');
    if (completed) {
      return <CompletedEvents routes={props.routes} />;
    } else {
      return (
        <>
          <FutureEvents
            days={days}
            hours={hours}
            minutes={minutes}
            seconds={seconds}
            completed={completed}
            routes={props.routes}
          />
        </>
      );
    }
  };

  return (
    <>
      <ToastContainer />
      <div>
        {!loading && singleEvent && (
          <>
            <Countdown
              date={
                singleEvent?.startTimePublic
                  ? singleEvent?.startTimePublic
                  : singleEvent?.startTime
              }
              renderer={Renderer}
            />
          </>
        )}
      </div>
    </>
  );
}

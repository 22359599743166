import * as classes from './PrivacyPolicy.module.css';

const PrivacyPolicy = (props) => {
  return (
    <div className={`${classes.tosContainer} font-100`}>
      <div
        className={`${classes.tosMainTitle} font-200 ${classes.tosMainTitle_bold_800}`}
      >
        PRIVACY POLICY
      </div>
      <br />
      <hr />
      <br />
      <p className={`${classes.tosMainTitle_p} `}>
        {' '}
        DTMEDIA (“we” or “us” or “our”) respects the privacy of our users
        (“user” or “you”). This Privacy Policy explains how we collect, use,
        disclose, and safeguard your information when you visit our website
        https://dtmedia.com.na/ including any other media form, media channel,
        mobile website, or mobile application related or connected thereto
        (collectively, the “DTMEDIA Website”). Please read this privacy policy
        carefully. If you do not agree with the terms of this privacy policy,
        please do not access the DTMEDIA Website.
      </p>
      <br />
      <p className={`${classes.tosMainTitle_p} `}>
        We reserve the right to make changes to this Privacy Policy at anytime
        and for any reason. We will alert you about any changes by updating the
        “LastUpdated” date of this Privacy Policy. Any changes or modifications
        will be effective immediately upon posting the updated Privacy Policy on
        the DTMEDIA Website, and you waive the right to receive specific notice
        of each such change or modification.
      </p>
      <br />
      <p className={`${classes.tosMainTitle_p} `}>
        You are encouraged to periodically review this Privacy Policy to stay
        informed of updates. You will be deemed to have been made aware of, will
        be subject to, and will be deemed to have accepted the changes in any
        revised Privacy Policy by your continued use of the DTMEDIA Website
        after the date such revised Privacy Policy is posted.
      </p>
      <br />
      <br />
      <div className={`${classes.tosTitle} font-200 `}>
        COLLECTION OF YOUR INFORMATION
      </div>
      <p className={`${classes.tosMainTitle_p} `}>
        We may collect information about you in a variety of ways. The
        information we may collect on the DTMEDIA Website includes:
      </p>
      <br />
      <div className={`${classes.tosTitle} font-200 `}>Personal Data</div>
      <p className={`${classes.tosMainTitle_p} `}>
        Personally identifiable information, such as your name, email address,
        phone number, and demographic information, such as your age, gender,
        hometown, and interests, that you voluntarily give to us when you
        register with the DTMEDIA Website, when you choose to participate in
        various activities related to the DTMEDIA Website such as online chat
        and message boards. You are under no obligation to provide us with
        personal information of any kind, however your refusal to do so may
        prevent you from using certain features of the DTMEDIA Website
      </p>
      <br />
      <div className={`${classes.tosTitle} font-200 `}>Derivative Data</div>
      <p className={`${classes.tosMainTitle_p} `}>
        Information our servers automatically collect when you access the
        DTMEDIA Website, such as your IP address, your browser type, your
        operating system, your access times, and the pages you have viewed
        directly before and after accessing the DTMEDIA Website. If you are
        using a mobile device, this information may also include your device
        name and type, your operating system, your phone number, your country,
        your likes and replies to a post, and other interactions with the
        application and other users via server log files, as well as any other
        information you choose to provide.
      </p>
      <br />
      <div className={`${classes.tosTitle} font-200`}>Financial Data</div>
      <p className={`${classes.tosMainTitle_p} `}>
        Financial information, such as data related to your payment method (e.g.
        valid debit or credit card number, card brand, expiration date) that we
        may collect when you purchase, order, return, exchange, or request
        information about our services from the DTMEDIA Website. We store only
        very limited, if any, financial information that we collect. Otherwise,
        all financial information is stored by our payment processor, Direct Pay
        Online and you are encouraged to review their privacy policy and contact
        them directly for responses to your questions.
      </p>
      <br />
      <div className={`${classes.tosTitle} font-200`}>
        USE OF YOUR INFORMATION
      </div>
      <p className={`${classes.tosMainTitle_p} `}>
        Having accurate information about you permits us to provide you with a
        smooth, efficient, and customized user experience. Specifically, we may
        use information collected about you via the DTMEDIA Website to:
      </p>
      <br />
      <div className={classes.padded}>
        <ul>
          <li>Create and manage your account.</li>
          <li>
            Deliver targeted advertising, newsletters, and other information
            regarding promotions.
          </li>

          <li>Email you regarding your account or order.</li>
          <li>
            use the DTMEDIA Website to advertise or offer to sell goods and
            services.
          </li>

          <li> Enable user-to-user communications.</li>

          <li>
            Fulfill and manage purchases, orders, payments, and other
            transactions related to the DTMEDIA Website{' '}
          </li>

          <li>
            Generate a personal profile about you to make future visits to the
            DTMEDIA Website more personalized.
          </li>

          <li>Increase the efficiency and operation of the DTMEDIA Website.</li>

          <li>
            Monitor and analyze usage and trends to improve your experience with
            the DTMEDIA Website.
          </li>
          <li>Notify you of updates to the DTMEDIA Website.</li>
          <li>Offer new products, services and/or recommendations to you.</li>
          <li>Perform other business activities as needed.</li>
          <li>
            Prevent fraudulent transactions, monitor against theft, and protect
            against criminal activity.
          </li>
          <li>Process payments and refunds.</li>
          <li>
            Request feedback and contact you about your use of the DTMEDIA
            Website.
          </li>
          <li>Resolve disputes and troubleshoot problems.</li>
          <li> Respond to product and customer service requests.</li>
        </ul>
      </div>
      <br />
      <div className={`${classes.tosTitle} font-200`}>
        DISCLOSURE OF YOUR INFORMATION
      </div>
      <p className={`${classes.tosMainTitle_p} `}>
        We may share information we have collected about you in certain
        situations. Your information may be disclosed as follows:
      </p>
      <br />
      <div className={`${classes.tosTitle} font-200`}>
        By Law or to Protect Rights
      </div>
      <p className={`${classes.tosMainTitle_p} `}>
        If we believe the release of information about you is necessary to
        respond to legal process, to investigate or remedy potential violations
        of our policies, or to protect the rights, property, and safety of
        others, we may share your information as permitted or required by any
        applicable law, rule, or regulation. This includes exchanging
        information with other entities for fraud protection and credit risk
        reduction.
      </p>
      <br />
      <div className={`${classes.tosTitle} font-200`}>
        Third-Party Service Providers
      </div>
      <p className={`${classes.tosMainTitle_p} `}>
        We may share your information with third parties that perform services
        for us or on our behalf, including payment processing, data analysis,
        email delivery, hosting services, customer service, and marketing
        assistance.
      </p>
      <br />
      <div className={`${classes.tosTitle} font-200`}>
        Marketing Communications
      </div>
      <p className={`${classes.tosMainTitle_p} `}>
        With your consent, or with an opportunity for you to withdraw consent,
        we may share your information with third parties for marketing purposes,
        as permitted by law.
      </p>{' '}
      <br />
      <p className={`${classes.tosMainTitle_p} `}>
        This license will apply to any form, media, or technology now known or
        hereafter developed, and includes our use of your name, company name,
        and franchise name, as applicable, and any of the trademarks, service
        marks, trade names, logos, and personal and commercial images you
        provide. You waive all moral rights in your Contributions, and you
        warrant that moral rights have not otherwise been asserted in your
        Contributions.
      </p>{' '}
      <br />
      <div className={`${classes.tosTitle} font-200`}>
        Interactions with Other Users
      </div>
      <p className={`${classes.tosMainTitle_p} `}>
        If you interact with other users of the DTMEDIA Website [and our mobile
        application], those users may see your name, profile photo, and
        descriptions of your activity, including sending invitations to other
        users, chatting with other users, liking posts, following blogs.
      </p>
      <br />
      <div className={`${classes.tosTitle} font-200`}>Online Postings</div>
      <p className={`${classes.tosMainTitle_p} `}>
        When you post comments, contributions or other content to the DTMEDIA
        Website [or our mobile applications], your posts may be viewed by all
        users and may be publicly distributed outside the DTMEDIA Website [and
        our mobile application] in perpetuity.
      </p>{' '}
      <br />
      <div className={`${classes.tosTitle} font-200`}>
        Third-Party Advertisers
      </div>
      <p className={`${classes.tosMainTitle_p} `}>
        We may use third-party advertising companies to serve ads when you visit
        the DTMEDIA Website [or our mobile application]. These companies may use
        information about your visits to the DTMEDIA Website [and our mobile
        application] and other webDTMEDIA Websites that are contained in web
        cookies in order to provide advertisements about goods and services of
        interest to you.
      </p>{' '}
      <br />
      <div className={`${classes.tosTitle} font-200`}>Affiliates</div>
      <p className={`${classes.tosMainTitle_p} `}>
        We may share your information with our affiliates, in which case we will
        require those affiliates to honor this Privacy Policy. Affiliates
        include our parent company and any subsidiaries, joint venture partners
        or other companies that we control or that are under common control with
        us.
      </p>{' '}
      <br />
      <div className={`${classes.tosTitle} font-200`}>Business Partners</div>
      <p className={`${classes.tosMainTitle_p} `}>
        We may share your information with our business partners to offer you
        certain products, services or promotions.
      </p>{' '}
      <br />
      <div className={`${classes.tosTitle} font-200`}>
        Social Media Contacts
      </div>
      <p className={`${classes.tosMainTitle_p} `}>
        If you connect to the DTMEDIA Website [or our mobile application]
        through a social network, your contacts on the social network will see
        your name, profile photo, and descriptions of your activity.]
      </p>{' '}
      <br />
      <div className={`${classes.tosTitle} font-200`}>
        TRACKING TECHNOLOGIES
      </div>
      <div className={`${classes.tosTitle} font-200`}>
        Cookies and Web Beacons
      </div>
      <p className={`${classes.tosMainTitle_p} `}>
        [We may use cookies, web beacons, tracking pixels, and other tracking
        technologies on the DTMEDIA Website [and our mobile application] to help
        customize the DTMEDIA Website [and our mobile application] and improve
        your experience. When you access the DTMEDIA Website [or our mobile
        application], your personal information is not collected through the use
        of tracking technology. Most browsers are set to accept cookies by
        default. You can remove or reject cookies, but be aware that such action
        could affect the availability and functionality of the DTMEDIA Website
        or our mobile application. You may not decline web beacons. However,
        they can be rendered ineffective by declining all cookies or by
        modifying your web browser’s settings to notify you each time a cookie
        is tendered, permitting you to accept or decline cookies on an
        individual basis.
      </p>{' '}
      <br />
      <p className={`${classes.tosMainTitle_p} `}>
        We may use cookies, web beacons, tracking pixels, and other tracking
        technologies on the DTMEDIA Website to help customize the DTMEDIA
        Website and improve your user experience.
      </p>{' '}
      <br />
      <div className={`${classes.tosTitle} font-200`}>THIRD-PARTY WEBSITES</div>
      <p className={`${classes.tosMainTitle_p} `}>
        The DTMEDIA Website may contain links to third-party websites and
        applications of interest, including advertisements and external services
        that are not affiliated with us. Once you have used these links to leave
        the DTMEDIA Website, any information you provide to these third parties
        is not covered by this Privacy Policy, and we cannot guarantee the
        safety and privacy of your information. Before visiting and providing
        any information to any third-party websites, you should inform yourself
        of the privacy policies and practices (if any) of the third party
        responsible for that website, and should take those steps necessary to,
        in your discretion, protect the privacy of your information. We are not
        responsible for the content or privacy and security practices and
        policies of any third parties, services or applications that may be
        linked to or from the DTMEDIA Website.
      </p>{' '}
      <br />
      <div className={`${classes.tosTitle} font-200`}>
        SECURITY OF YOUR INFORMATION
      </div>
      <p className={`${classes.tosMainTitle_p} `}>
        We use administrative, technical, and physical security measures to help
        protect all your information. While we have taken reasonable steps to
        secure your information you provide to us, please be aware that despite
        our efforts, no security measures are perfect or impenetrable, and no
        method of data transmission can be guaranteed against any interception
        or other type of misuse. Any information disclosed online is vulnerable
        to interception and misuse by unauthorized parties. Therefore, we cannot
        guarantee complete security of your information.
      </p>{' '}
      <br />
      <div className={`${classes.tosTitle} font-200`}>
        OPTIONS REGARDING YOUR INFORMATION
      </div>
      <div className={`${classes.tosTitle} font-200`}>Account Information</div>
      <p className={`${classes.tosMainTitle_p} `}>
        You may at any time review or change your account information or
        terminate your account by:
      </p>{' '}
      <div className={classes.padded}>
        <ul>
          <li>Logging into your account settings and updating your account</li>
          <li>Contacting us using the contact information provided below</li>
        </ul>
        <br />
        <p className={`${classes.tosMainTitle_p} `}>
          Upon your request to terminate your account, we will deactivate or
          delete your account and information from our active databases.
          However, some information may be retained in our files to prevent
          fraud, troubleshoot problems, assist with any investigations, enforce
          our Terms of Use and/or comply with legal requirements.
        </p>{' '}
        <br />
      </div>
      <br />
      <div className={`${classes.tosTitle} font-200`}>
        Emails and Communications
      </div>
      <p className={`${classes.tosMainTitle_p} `}>
        If you no longer wish to receive correspondence, emails, or other
        communications from us, you may opt-out by:
      </p>{' '}
      <div className={classes.padded}>
        <ul>
          <li>
            Noting your preferences at the time you register your account with
            the DTMEDIA Website
          </li>
          <li>
            Logging into your account settings and updating your preferences.
          </li>
          <li>Contacting us using the contact information provided below</li>
        </ul>
        <br />
        <p className={`${classes.tosMainTitle_p} `}>
          If you no longer wish to receive correspondence, emails, or other
          communications from third parties, you are responsible for contacting
          the third party directly.
        </p>{' '}
        <br />
      </div>
      <br />
      <div className={`${classes.tosTitle} font-200`}>CONTACT US</div>
      <p className={`${classes.tosMainTitle_p} `}>
        In order to resolve a complaint regarding the DTMEDIA Website or to
        receive further information regarding use of the DTMEDIA Website, please
        contact us at:
      </p>
      <br />
      <p className={`${classes.tosMainTitle_p} `}>DTMEDIA</p>
      <p className={`${classes.tosMainTitle_p} `}>DAVID TIBOTH</p>
      <p className={`${classes.tosMainTitle_p} `}>CHIEF EXECUTIVE OFFICER</p>
      <p className={`${classes.tosMainTitle_p} `}>
        WINDHOEK, REPUBLIC OF NAMIBIA
      </p>
      <p className={`${classes.tosMainTitle_p} `}>+264 81 27 19019</p>
      <p className={`${classes.tosMainTitle_p} `}>
        <a href='mailto:david@dtmedia.com.na'>david@dtmedia.com.na</a>
      </p>
    </div>
  );
};

export default PrivacyPolicy;
